<template>
    <div>
        <BillComp/>
    </div>
</template>

<script>
export default {
  name: "Bills",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Billing", path:"", isActive: true}] 
    }
  },
  components: {
    BillComp: () => import("../components/Bill/Bills"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>